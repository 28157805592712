<template>
  <asom-alert variant="success" v-if="!isOnline" title="Offline Support Available">
    <p class="text-sm pt-1">This action can be performed offline. However, for completion, it would require to be synced when app is online.</p>
  </asom-alert>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      'isOnline': "apiStatus/isAllApiOnline"
    })
  }
}
</script>

<style>

</style>