<template>
<div>
  <div class="mt-3 flex space-x-3">
    <asom-input-text v-model="code" :state="state" :disabled="disabled" />
    <asom-bar-code-scanner v-if="!disabled"
      @detect="logIt">
    </asom-bar-code-scanner>
  </div>
</div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
    },
    state: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: [ 'update:modelValue', 'error' ],
  data() {
    return {
      format: "",
    };
  },
  mounted() {
    if (this.props && this.props.modelValue)
      this.code = this.props.modelValue || '';
  },
  computed: {
    code: {
      get() {
        return this.modelValue || ''
      },
      set(newValue) {
        this.$emit('update:modelValue', newValue);
      }
    }
  },
  methods: {
    logIt(value) {
      this.openScanner = false;
      if (value)
        this.$emit('update:modelValue', value);
      else 
        this.$emit('error', value);
    },
  }
};
</script>