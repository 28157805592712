<template>
  <div class="space-y-4">
    <section class="space-y-10">
      <offline-action-alert v-if="!isOnline" />
      <QuickInfo />
      <asom-alert v-if="error" variant="error" :error-message="error" />
      <article>
        <h3 class="subheader">
          Move Cashbox
        </h3>
        <br />
        <asom-card>
          <section class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
            <asom-form-field label="Cashbox Movement" class="col-span-2">
              <asom-input-radio-group
                class="mt-3"
                horizontal
                v-model="formData.fromAFC"
                :options="movementOptions"
              />
            </asom-form-field>
            <asom-form-field
              :label="'GTM/TUK'"
              class=""
              :state="inputStates('formData.gtmId')"
              error="Please select a GTM/TUK"
            >
              <asom-input-radio-group
                class="pt-3"
                horizontal
                v-model="formData.gtmId"
                :options="gtmOptions"
                :state="inputStates('formData.gtmId')"
              />
            </asom-form-field>
            <asom-form-field :label="'AFC'" class="">
              <p class="mt-3 text-sm text-gray-500">AFC</p>
            </asom-form-field>
            <asom-form-field
              label="Cash Box Serial Number"
              required
              :state="inputStates('formData.cashBoxSerialNo')"
              error="Cash Box Serial Number is required"
            >
              <scan-code
                v-model="formData.cashBoxSerialNo"
                :state="inputStates('formData.cashBoxSerialNo')"
              />
            </asom-form-field>
            <asom-form-field :label="'Remarks'" class="col-span-2">
              <asom-input-textarea v-model="formData.remarks" />
            </asom-form-field>
          </section>
          <template #footer>
            <div class="col-span-3 flex justify-between flex-1">
              <asom-button
                variant="link"
                text="Cancel"
                @click="$router.push({ name: 'Cashbox Main Page' })"
              />
              <asom-button text="Confirm" @click="onConfirmBtnClicked" />
            </div>
          </template>
        </asom-card>
      </article>
    </section>
    <asom-modal title="Confirm" v-model="showModal" :dismissible="false">
      <p class="pt-4">Are you sure you would like to proceed?</p>
      <div class="flex flex-row-reverse pt-4">
        <div class="pl-4">
          <asom-button
            @click="onSubmit"
            :disabled="isSubmitting"
            :loading="isSubmitting"
            text="OK"
          />
        </div>
        <div>
          <asom-button
            @click="onCloseModal"
            text="Cancel"
            variant="secondary"
          />
        </div>
      </div>
    </asom-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import QuickInfo from "../../_QuickInfo.vue";
import ScanCode from "../../_ScanCode.vue";
import { moveCashBox } from "../../../../services/cashManagement.service";
import OfflineActionAlert from "@/layout/OfflineActionAlert"

export default {
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  components: {
    QuickInfo,
    ScanCode,
    OfflineActionAlert,
  },
  data() {
    return {
      isSubmitting: false,
      error: null,
      showModal: false,
      formData: {
        gtmId: null,
        fromAFC: true,
        cashBoxSerialNo: "",
        remarks: "",
      },
    };
  },
  validations() {
    return {
      formData: {
        gtmId: {
          required,
        },
        cashBoxSerialNo: {
          required,
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      afcId: "cashManagement/stationCashAccounts/afcId",
      gtmOptions: "cashManagement/stationCashAccounts/gtmOptions",
      rosterPlanDate: "currentRoster/currentRosterPlanDate",
      rosterDws: "currentRoster/currentRosterShift",
      isOnline: "apiStatus/isCMModuleOnline",
    }),
    movementOptions() {
      return [
        { label: "Insert into GTM/TUK", value: true },
        { label: "Retrieve from GTM/TUK", value: false },
      ];
    },
    submissionData() {
      return {
        AFCId: this.afcId,
        GTMId: this.formData.gtmId,
        fromAFC: this.formData.fromAFC,
        cashBoxSerialNo: this.formData.cashBoxSerialNo.trim(),
        remarks: this.formData.remarks,
        rosterPlanDate: this.rosterPlanDate,
        rosterDWS: this.rosterDws,
      }
    }
  },
  methods: {
    onConfirmBtnClicked() {
      this.v$.$reset();
      this.v$.$touch();
      if (this.v$.$invalid) {
        this.error = "Please completed all required fields.";
        this.$scrollTop();
        return;
      } else {
        this.showModal = true;
      }
    },
    async onSubmit() {
      this.error = null;
      if (this.isOnline) {
        this.moveCashBoxesAsync();
      } else {
        this.$store.dispatch('offlineData/moveCashBoxes', this.submissionData);
        this.showModal = false;
        this.$router.push({ name: "Cashbox Main Page" });
      }
    },
    async moveCashBoxesAsync() {
      this.isSubmitting = true;
      const result = await moveCashBox(this.submissionData);
      this.isSubmitting = false;
      if (!result.success) {
        this.error = result.payload;
        this.showModal = false;
        this.$scrollTop();
      } else {
        this.$router.push({ name: "Cashbox Main Page" });
      }
    },
    onCloseModal() {
      this.showModal = false;
    },
  },
};
</script>
